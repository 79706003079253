import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import api from '../../plugins/api'

import customer from './modules/customer'
import app from './modules/app'
import model from './modules/model'
import logs from './modules/logs'
import user from './modules/user'
import users from './modules/users'
import account from './modules/account'
import licences from './modules/licences'
import aws from './modules/aws'
Vue.use(Vuex)
Vue.use(api(Vuex))
export default new Vuex.Store({
  state: {
  },
  mutations: {
    SIGNOUT (state) {
      state.user = {}
      state.account = {
        token: '',
        tokenExpiredAt: new Date()
      }
    }
  },
  actions: {
  },
  modules: {
    user,
    customer,
    account,
    app,
    model,
    users,
    licences,
    logs,
    aws
  },
  plugins: [createLogger({})]
})
