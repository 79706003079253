
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  deleteLogs ({ commit }, payload) {
    return api('/api/admin/logs', 'DELETE', payload)
  },
  getLogsList ({ commit }, payload) {
    return api('/api/admin/logs', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
