
import api from '@/utils/api'

const state = {
  user: {}
}

const getters = {
  getUser (state) {
    return state
  }
}

const actions = {
  setUser (contaxt, user) {
    contaxt.commit('SET_USER', user)
  },
  getUser ({ commit }, payload) {
    return api('/api/admin/user', 'GET', payload)
  }
}

const mutations = {
  SET_USER (state, { user }) {
    state.user = user
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
