import Vue from 'vue'
import VueRouter from 'vue-router'
//import HelloWorld from '@/components/HelloWorld'
const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: () => import('../views/ForgotPasswordByEmail.vue')
  },
  {
    path: '/resetpassword',
    name: 'Reset Password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/work',
    component: () => import('../views/WorkSpace.vue'),
    children: [
      {
        path: '/work/',
        name: 'Customers',
        component: () => import('../views/Customers.vue')
      },
      {
        path: '/work/customers',
        name: 'Customers',
        component: () => import('../views/Customers.vue')
      },
      {
        path: '/work/customers/:id',
        name: 'Customer',
        component: () => import('../views/CustomerDetail.vue')
      },
      {
        path: '/work/app',
        name: 'AppReleases',
        component: () => import('../views/AppReleases.vue')
      },
      {
        path: '/work/app/:id',
        name: 'AppRelease',
        component: () => import('../views/AddAppRelease.vue')
      },
      {
        path: '/work/model',
        name: 'ModelReleases',
        component: () => import('../views/ModelReleases.vue')
      },
      {
        path: '/work/model/:id',
        name: 'ModelRelease',
        component: () => import('../views/AddModelRelease.vue')
      },
      {
        path: '/work/users',
        name: 'Users',
        component: () => import('../views/Users.vue')
      },
      {
        path: '/work/users/:id',
        name: 'User',
        component: () => import('../views/AddUser.vue')
      },
      {
        path: '/work/logs',
        name: 'Logs',
        component: () => import('../views/Logs.vue')
      } 
    ],
    beforeEnter: (to, from, next) => {
      var account = global.defaultStore.state.account
      if (account && account.token && account.tokenExpiredAt > new Date()) {
        global.defaultStore.httpDispatch()('signinByToken').then(res => {
          const { code, data } = res.data
          if (code !== 0) {
            localStorage.clear('token')
            localStorage.clear('tokenExpiredAt')
            next('/')
          } else {
            try {
              global.defaultStore.dispatch('setUser', { user: data.user })
            } catch (err) {
              console.log(err)
            }
            next()
          }
        }).catch(() => {
          localStorage.clear('token')
          localStorage.clear('tokenExpiredAt')
          next('/')
        })
      } else {
        next('/')
      }
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name
  }
  next()
})
export default router
