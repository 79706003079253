
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  updateCustomer ({ commit }, payload) {
    return api('/api/admin/customer', 'put', payload)
  },
  addCustomer ({ commit }, payload) {
    return api('/api/admin/customer', 'POST', payload)
  },
  deleteCustomer ({ commit }, payload) {
    return api('/api/admin/customer', 'DELETE', payload)
  },
  getCustomerList ({ commit }, payload) {
    return api('/api/admin/customers', 'GET', payload)
  },
  getAllCustomer ({ commit }, payload) {
    return api('/api/admin/customers/all', 'GET', payload)
  },
  getCustomer ({ commit }, payload) {
    return api('/api/admin/customer', 'GET', payload)
  },
  getDownloadLogs ({ commit }, payload) {
    return api('/api/admin/downloads', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
