
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  revokeLicences ({ commit }, payload) {
    return api('/api/admin/licence', 'PUT', payload)
  },
  addLicence ({ commit }, payload) {
    return api('/api/admin/licence', 'POST', payload)
  },
  deleteLicence ({ commit }, payload) {
    return api('/api/admin/licence', 'DELETE', payload)
  },
  getLicenceList ({ commit }, payload) {
    return api('/api/admin/licences', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
