
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  updateModel ({ commit }, payload) {
    return api('/api/admin/model', 'put', payload)
  },
  addModel ({ commit }, payload) {
    return api('/api/admin/model', 'POST', payload)
  },
  deleteModel ({ commit }, payload) {
    return api('/api/admin/model', 'DELETE', payload)
  },
  getModelList ({ commit }, payload) {
    return api('/api/admin/models', 'GET', payload)
  },
  getModel ({ commit }, payload) {
    return api('/api/admin/model', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
