
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  updateUser ({ commit }, payload) {
    return api('/api/admin/user', 'put', payload)
  },
  addUser ({ commit }, payload) {
    return api('/api/admin/user', 'POST', payload)
  },
  deleteUser ({ commit }, payload) {
    return api('/api/admin/user', 'DELETE', payload)
  },
  getUserList ({ commit }, payload) {
    return api('/api/admin/users', 'GET', payload)
  },
  getUserObj ({ commit }, payload) {
    return api('/api/admin/user', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
